<template>
  <div class="hixianchang-previewbox">
    <div class="hixianchang-band" v-if="pre == 1">
      <img src="../images/hixianchang_iphone.png" />
    </div>
    <div class="hixianchang-band-pc" v-if="pre == 2">互动游戏仅支持在WAP端展示</div>
  </div>
</template>
<script>
  export default {
    name: 'component-hixianchang',
    props: {
      menuInfo: {
        required: false
      },
      units: {
        required: false
      },
      pre: {
        required: true
      }
    },
    data() {
      return {};
    },
    computed: {},
    mounted() {
      console.log('component-hixianchang-------------->', this.menuInfo, this.units, this.pre);
    }
  };
</script>
<style lang="less" scoped>
  // .vh-customer__preview-pc {
  // }

  .hixianchang-previewbox {
    height: 100%;
    overflow: auto;
    padding: 0 10px;
    .hixianchang-band {
      background: #fff;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
</style>
